.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  min-width: 300px;
  position: relative;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
}

.add-new{
  font-size: 16px;
  width: 35px;
  height: 35px;
  border-radius: 5px;
  background: #ffc83f;
  border: 0px;
}
.custom-data .title{
  font-size: 18px;
  font-weight: 500;
  color: #000;
}
.custom-data .field{
 padding: 0px;
 background: unset;
}
.custom-data .field label{
  font-size: 14px;
}
.custom-data .field input{
  width: 100%;
  margin-top: 4px;
  font-size: 15px;
  border-radius: 8px;
}
.css_submit_btn,
form button {
  width: 100%;
  padding: 10px 21px;
  border: 0px;
  border-radius: 10px;
  background: #313642;
  color: #fff;
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 0.5px;
  margin-top: 20px;
}
.css_submit_btn:hover,
form button:hover {
  color: #fff;
}

.modal-backdrop{
 z-index: 9999999;
}

.modal{
  z-index: 9999999;
}
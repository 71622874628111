aside.sidebar {
  width: var(--sidebar-width);
  background: var(--primary-color);
  text-align: left;
  transition: 0.3s;
  color: var(--text-color);
  box-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  min-height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 9;
  overflow: hidden;
}
.overlay{
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #00000040 !important;
  z-index: 9;
}
.body-wrapper {
  width: calc(100vw - var(--sidebar-width));
  margin-left: auto;
}

main {
  margin-top: 80px;
}

section {
  padding: 16px 20px;
  background-color: #f4f7f9;
  min-height: calc(100vh);
}

/* sidebar css */
.brand-logo {
  box-shadow: -9px 0 20px rgba(89, 102, 122, 0.1);
}
.brand-logo img{
  margin-left: 35px;
  height: 75px;
}
.brand-logo .img{
  display: none;
}
.navbar .active-link {
  color: var(--secondary-color);
  color: #2a3650;
  font-weight: 600;
  font-size: 16px;
  background-color: #fff;
  height: 50px;
  width: 104%;
  border-radius: 0px;
}
.navbar li a {
  padding: 16px 24px;
  display: flex;
  text-decoration: none;
  align-items: center;
  justify-content: start;
  letter-spacing: 1px;
  font-size: 1rem;
  gap: 22px;
  padding: 16px;
}
.navbar li svg {
  height: 20px;
  width: 28px;
}

/* top-header */
header {
  width: calc(100vw - var(--sidebar-width));
  max-width: 100vw;
  position: fixed;
  top: 0;
  z-index: 999999;
  transition: 0.5s;
  background-color: #fff;
  box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 80px;
}
header > div {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
header .left-side-header h2 {
  color: var(--primary-color);
  font-weight: 600;
  font-size: 22px;
}
header .left-side-header .brand-selector button:hover,
header .left-side-header .brand-selector button:focus,
header .left-side-header .brand-selector button {
  color: var(--primary-color);
  background-color: var(--selector-color);
  border-color: var(--selector-color);
  border-radius: 10px;
  color: #000;
  font-size: 14px;
  font-weight: 500;
}
header .left-side-header .brand-selector .dropdown-toggle::after{
  margin-left: 10px;
}
header .left-side-header .dropdown-item:hover{
  background-color: #ffc83f30;
}
header .right-side-header img {
  width: 30px;
}
header .right-side-header p {
  font-size: 15px;
  color: #000;
  font-weight: 600;
}
.profile-dropdown {
  position: relative;
}
.profile-dropdown ul {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: 32px;
  right: 0;
  width: 200px;
  background: #fff;
  padding: 10px 20px !important;
  border-radius: 10px;
  box-shadow: 0px 4px 40px rgba(39, 32, 120, 0.1);
}
.profile-dropdown:hover ul {
  visibility: visible;
  opacity: 1;
}
.profile-dropdown li {
  margin-bottom: 10px;
}
.profile-dropdown li a {
  display: flex;
  gap: 0.5rem;
  align-items: center;
  text-transform: capitalize;
  letter-spacing: 1px;
  font-size: 15px;
}
.profile-dropdown li a svg {
  width: 17px;
  height: 17px;
}
aside.sidebar{
  z-index: 9999999;
}
.wrapper .overlay{
  z-index: 999999;
}
.overlay.overlay-filter{
  z-index: 9;
}
.menu {
  font-size: 29px;
  height: 75px;
}
.menu > span{
  width: 30px;
}
.menu > span svg{
  position: absolute;
  top: 20px;
  left: 18px;
}
@media (max-width: 1200px){

  aside.sidebar.mobile-sidebar{
    overflow-x: hidden;
  }
  .mobile-sidebar .navbar li a{
    gap: 1.4rem;
    padding: 16px;
  }

  .mobile-sidebar .brand-logo{
    padding: 0 10px;
  }
  .mobile-sidebar .brand-logo .img{
    display: block;
    text-align: center;
    margin-top: 22px;
    position: fixed;
    bottom: 15px;
    left: 15px;
  }
  .mobile-sidebar .brand-logo .img img{
    width: 30px;
  }
  .mobile-sidebar .brand-logo > a img{
    /* position: fixed;
    bottom: -15px;
    left: -15px;
    transition: all 0.5s;
    width: var(--sidebar-width); */
  }
  .body-wrapper {
    width: unset;
    margin-left: 60px;
  }
  header{
    width: calc(100vw - 60px);
  }
  header .left-side-header h2{
    display: none;
  }
  nav{
    width: 300px;
  }
}
@media (max-width: 767px){
  .right-side-header > p{
    display: none;
  }
}
@media (max-width: 500px){
  section {
    padding: 10px;
  }
  .card-body{
    padding: 10px;
  }
}
.brand-selector .dropdown-menu {
  max-height: 70vh;
  overflow-y: scroll;
}

/* date time css */

.date-wrapper,
.react-datepicker-wrapper{
  position: relative;
}
.react-datepicker__input-container{
  width: 120px !important;
}
.react-datepicker__input-container > input{
  background: #f0f0f0;
  color: black;
}
.react-datepicker-popper{
  position: unset !important;
  transform: translate(0, 50px) !important;
}

.output:hover,
.output:focus,
.output:active,
.output{
  background: #f0f0f0 !important;
  color: black;
  font-size: 14px;
  border: 0px;
}
.date-range{
  position: relative;
}
.date-filter{
  position: absolute;
  top: calc(100% +  10px);
  right: -6px;
  background: #fcfafa;
  padding: 10px;
  display: flex;
  gap: 1rem;
  box-shadow: 0px 9px 20px rgb(128 128 132) !important;
  z-index: 99;
}
.date-filter .reset-style button:focus{
  outline: none;
  box-shadow: none !important;
}
.date-filter .reset-style button{
  padding: 10px;
  background-color: #f0f0f0 !important;
  box-shadow: none !important;
}
.date-filter .accordion-button::after{
  display: none;
}
.custom-date-box{
  width: 140px;
}
.custom-date-box .accordion-body{
  padding: 6px 10px;
}
.date-range .react-datepicker{
  height: 90%;
}
.date-range .accordion,
.custom-date-box .accordion-item{
  height: 100%;
}
.date-range .react-datepicker__day--outside-month {
  color: #d3d3d3; /* Light gray color */
}
.custom-date-box li{
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
  color: #565656;
  margin-bottom: 4px;
}
.apply-custom-date span{
  cursor: pointer;
}
.prevents{
  pointer-events: none !important;
}
.form-check-input:focus{
  box-shadow: none !important;
}
.form-check-input{
  width: 36px;
  border-width: 2px;
  padding: 7px;
}

@media (max-width: 991px) {
  .date-filter{
    right: 0%;
    transform: translateX(0%);
    flex-wrap: wrap;
    gap: 0.5rem;
  }
  .date-filter  .custom-date-box{
    width: 100%;
  }
}
@media (min-width: 767px){
  .date-range .output{
    z-index: 99999;
    position: relative;
  }
}
@media (max-width: 620px){
  .date-filter{
    position: fixed;
    right: 0;
    flex-wrap: wrap;
    gap: 0.5rem;
    width: calc(100vw - 127px);
    left: 56%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .date-filter .mob-width{
    width: calc(100vw - 141px);
  }
  .float-width{
    max-width: calc(100vw - 150px);
    overflow: scroll;
    flex-wrap: wrap;
  }
  .apply-custom-date span{
    padding: 4px 10px;
    background: #0d6efd;
    color: #fff;
    border-radius: 10px;
  }
}
@media (max-width: 590px){
  .float-width .start-date,
  .float-width .end-date{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 500px){
  .date-filter{
    width: calc(100vw - 97px);
  }
  .date-filter .mob-width{
    max-width: calc(100vw - 100px);
  }
  .float-width{
    max-width: calc(100vw - 120px);
    overflow: scroll;
  }
}

.fade.alert.alert-success.show{
  padding: 0;
  color: green;
  background: unset;
  border: 0px;
  margin: 0;
}
/* .float-bottom{
  position: fixed;
  bottom: 0;
  width: calc(100% - 290px);
  left: 270px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background: white;
  padding: 10px;
  border-radius: 10px;
} */
.float-bottom{
  position: fixed;
  top: 0;
  width: calc(100% - 290px);
  left: 270px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  background: white;
  padding: 10px 10px 0;
  border-radius: 10px;
  z-index: 9999999;
  margin-top: 10px;
}
.float-bottom .red-text {
  border: 0;
  color: red;
  background: #ff000017;
}
.discard-btn:hover,
.discard-btn{
  background: white;
}
.float-bottom  > * {
  
}
.float-bottom button{
  width: unset;
}

.address-tab .selector button{
  border: 1px solid #f0f0f0;
  background: #fff;
  color: #000;
  text-align: start;
  width: 100%;
  margin-top: 6px;
  position: relative;
}

.showicon ul li a span{
  display: none;
}
li.LogOut{
  position: fixed;
  bottom: 0;
  width: 250px;
}

.analytics-wrapper h3.efficiency-text{
  font-size: 18px;
}

.analytics-wrapper h3.efficiency-text + p{
  font-size: 14px;
}
/************************
* font family  CSS      *
*************************/
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/************************
* Global Settings       *
*************************/
:root {
  --primary-color: #2a3650;
  --secondary-color: #7a70ba;
  --text-color: #fff;
  --sidebar-width: 250px;
  --selector-color: #ffc83f;
  --brand-bg-color: #98805f;
  --brand-txt-color: #fff;
  --payment-bg-color: #98805f;
  --payment-txt-color: #fff;
  --address-bg-color: #98805f;
  --address-txt-color: #fff;
  --login-bg-color: #98805f;
  --login-txt-color: #fff;
}
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
*::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  color: #666666;
  overflow-x: hidden;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  margin: 0px;
}
label {
  font-weight: 400;
  color: #333333;
}
label .required {
  color: #ff2727;
  border-bottom: none;
  text-decoration: none;
}
img {
  max-width: 100%;
  height: auto;
  outline: none;
}
figure {
  max-width: 100%;
}
input:focus {
  outline: none;
}
a,
a:hover,
a:focus,
a:visited,
a:active,
button:hover,
button:focus,
button:visited,
button:active {
  text-decoration: none;
  outline: none;
  color: inherit;
  cursor: pointer;
}
table {
  border: 1px solid #e6e6e6;
  border-collapse: collapse;
  border-spacing: 0;
  table-layout: auto;
  width: 100%;
  margin-bottom: 20px;
}
th,
td {
  border: 1px solid #e6e6e6;
  padding: 10px 5px;
  font-weight: normal;
  text-align: center;
}
textarea {
  display: block;
}
input[type="checkbox"],
input[type="radio"]{
  cursor: pointer;
}
input[type="text"],
input[type="email"],
textarea,
input[type="password"],
input[type="tel"],
input[type="date"],
select,
input[type="search"] {
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  padding: 7px 20px 7px 10px;
  max-width: 100%;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="date"]{
  padding: 7px;
}
input[type="text"]::-ms-input-placeholder,
input[type="date"]::-ms-input-placeholder,
input[type="email"]::-ms-input-placeholder,
input[type="password"]::-ms-input-placeholder,
input[type="tel"]::-ms-input-placeholder,
input[type="search"]::-ms-input-placeholder {
  color: #888888;
}
input[type="text"]:-ms-input-placeholder,
input[type="email"]:-ms-input-placeholder,
input[type="password"]:-ms-input-placeholder,
input[type="tel"]:-ms-input-placeholder,
input[type="search"]:-ms-input-placeholder {
  color: #888888;
}
input[type="text"]::placeholder,
input[type="email"]::placeholder,
input[type="date"]::placeholder,
input[type="password"]::placeholder,
input[type="tel"]::placeholder,
input[type="search"]::placeholder {
  color: #888888;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  background-color: #ffffff00 !important; /* or any desired color */
}

input:-internal-autofill-selected {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000 !important;
  background-color: #ffffff00 !important; /* or any desired color */
}

select {
  background: transparent;
  border: none;
  color: #ffffff;
}
select option {
  color: #333333;
}
ul{
  list-style: none;
  padding-left: 0px;
}
p{
  margin-bottom: 0px;
}
@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}
input[readonly] {
  opacity: 0.5;
  cursor: not-allowed;
}
html,
body {
  height: 100%;
  overflow-x: hidden;
}
::selection {
  background-color: #7a70ba;
  color: #fff;
}
/* loader css */
.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #3636362b;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading {
  height: 0;
  width: 0;
  padding: 20px;
  border: 8px solid #fff;
  border-right-color: var(--primary-color);
  border-radius: 50px;
  animation: rotate 1s infinite linear;
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
.green {
  color: #03bc03 !important;
  text-align: end;
  font-weight: 600;
}
.green:hover {
  color: #0aa90a !important;
}
.pointer{
  cursor: pointer;
}
.red {
  color: #fa0c0c !important;
  text-align: end;
  font-weight: 600;
}
.red.error-txt{
  font-weight: 400;
  font-size: 12px;
  text-align: left;
}
.red:hover {
  color: #bc0303 !important;
}
.err-text{
  color: #fa0c0c !important;
  font-size: 12px;
}

@media (max-width: 768px){
  html, body{
    font-size: 14px;
  }
}
/************************
* Components CSS      *
*************************/

@import url('./Components/boxWrapper.css');
@import url(./Components/modal.css);
@import url(./Components/toast.css);
@import url(./Components/page.css);

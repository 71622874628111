.toast-wrapper {
  position: fixed;
  bottom: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.toast {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  margin: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.toast > div{
  margin-right: 20px;
}

.close-button {
  background: none;
  border: none;
  color: #fff;
  font-size: 1.2em;
  line-height: 24px;
  cursor: pointer;
}

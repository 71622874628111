.box-wrapper {
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 17px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.box-wrapper .card {
  margin-bottom: 30px;
  border: none;
  transition: all 0.3s ease;
  letter-spacing: 0.5px;
  border-radius: 17px;
  box-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
}
.box-wrapper .card .card-header {
  background-color: #fff;
  padding: 16px 16px 0;
  border-bottom: 0px dashed rgba(106, 113, 133, 0.3);
  border-top-left-radius: 17px;
  border-top-right-radius: 17px;
  position: relative;
  text-transform: capitalize;
}
.box-wrapper .card-header p.dark,
.box-wrapper .card-header p.light {
  font-size: 18px;
  font-weight: 600;
  color: #8d8d8d;
}
.box-wrapper .card-header p.light {
  color: #ffc83f;
}
.box-wrapper .card-header p.dark {
  color: #000;
}
.box-wrapper .card-body .total {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: start;
}
.box-wrapper .card-body .img {
  position: relative;
  width: 52px;
  height: 52px;
  border-radius: 50px;
  display: flex;
  margin: 4px;
  align-items: center;
  justify-content: center;
}
.sells .box-wrapper .card-body .img {
  background-color: var(--secondary-color);
}
.order .box-wrapper .card-body .img {
  background-color: #48a3d7;
}
.daily .box-wrapper .card-body .img {
  background-color: #d77748;
}
.revenue .box-wrapper .card-body .img {
  background-color: #c95e9e;
}
.box-wrapper .card-body .img::before {
  content: "";
  position: absolute;
  width: 115%;
  height: 115%;
  opacity: 0;
  border-radius: 50%;
  border: 2px dashed;
  padding: 10px;
  transition: 0.8s;
  animation: spin 10s infinite linear;
}
.sells .box-wrapper .card-body .img::before {
  border-color: var(--secondary-color);
}
.order .box-wrapper .card-body .img::before {
  border-color: #48a3d7;
}
.daily .box-wrapper .card-body .img::before {
  border-color: #d77748;
}
.revenue .box-wrapper .card-body .img::before {
  border-color: #c95e9e;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
.box-wrapper .card-body:hover .img::before {
  opacity: 1;
}
.box-wrapper .card-body:hover img {
  animation: tada 1.5s ease infinite;
}
@keyframes tada {
  0% {
    transform: scaleX(1);
  }
  10% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate(-3deg);
  }
  30% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  50% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  70% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(3deg);
  }
  40% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  60% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate(-3deg);
  }
  100% {
    transform: scaleX(1);
  }
}
.box-wrapper .total .count {
  font-size: 22px;
  font-weight: 600;
  letter-spacing: 0.6px;
}
.box-wrapper .total .text {
  font-size: 14px;
  color: #8d8d8d;
  font-weight: 500;
}

/* box shadow for not logged in pages */
.box-shadow {
  padding: 20px;
  -webkit-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.24);
  -moz-box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.24);
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.24);
  border-radius: 10px;
  width: 100%;
  border: 2px solid #313642;
}


/* card box css */
.card-box{
  padding: 1rem;
  margin-bottom: 30px;
}
.card-box h4{
  color: #000;
  font-size: 18px;
  font-weight: 600;
}
.custom-padding .card-header{
  padding: 16px 16px 0 !important;
}
@media (max-width: 767px){
  .box-wrapper .card .card-header{
    padding: 16px 1rem 0 !important;
  }
}
@media (max-width: 495px){
  .custom-padding .box-wrapper .card-header p.light{
    font-size: 14px;
    font-weight: 500;
  }
  .custom-padding .box-wrapper .total .count{
    font-size: 16px;
  }
  .custom-padding .box-wrapper .card .card-header{
    padding: 8px 0.5rem 0 !important;
  }
  .custom-padding .card-body{
    padding: 0 0.5rem 8px;
  }
}